/* Estilo para la barra de desplazamiento en WebKit (Chrome, Safari) */
body {
    background-color: #121212; /* Fondo oscuro */
    color: #EAEAEA; /* Color del texto */
    overflow-y: scroll; /* Asegúrate de que el scroll esté habilitado */
}

/* Ajuste para el contenido debajo del header */
div {
    margin-top: auto; /* Ajusta este valor según la altura de tu header */
}


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #1E1E1E; /* Color del fondo del track */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #BB86FC; /* Color del pulgar */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9B59B6; /* Color al pasar el ratón */
}

/* Estilo para Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #BB86FC #1E1E1E; /* Color del pulgar y del track */
}
