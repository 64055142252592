.contact {
    background-color: #121212;
    color: #EAEAEA;
    text-align: center;
    padding: 60px 20px;
}

.contact h2 {
    color: #BB86FC;
    margin-bottom: 20px;
}

.contact p {
    margin-bottom: 30px;
    color: #B0BEC5;
}

.icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.icon {
    font-size: 2rem;
    color: #BB86FC;
    transition: color 0.3s;
}

.icon:hover {
    color: #4A90E2;
    transform: scale(1.2);
}
.footer {
    margin-top: 20px;
    text-align: center;
    font-size: 0.9rem;
    color: #666;
}

.footer p {
    margin: 0;
    padding: 0;
}
