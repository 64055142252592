/* src/styles/Header.module.css */

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #1E1E1E;
    padding: 20px 0;
    color: #EAEAEA;
    text-align: center;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.navList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}

.navList li {
    margin: 0;
}

.navList a {
    color: #BB86FC;
    text-decoration: none;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 30px;
    background-color: #2D2D2D;
    border: 2px solid transparent;
    display: inline-block;
    cursor: pointer;
    position: relative;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

/* Efecto hover */
.navList a:hover {
    background-color: #BB86FC;
    color: #ffffff;
    border-color: #BB86FC;
}

/* Media Query para pantallas pequeñas */
@media (max-width: 600px) {
    .header {
        padding: 15px 0;
    }

    .navList {
        gap: 10px;
    }

    .navList li {
        width: auto;
        text-align: center;
        margin-bottom: 10px;
    }
}
